.App {
  max-width: 700px;
  padding: 20px;
  line-height: 1.4em;
  margin: 0 auto; /* Center the entire content horizontally */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
}

.options {
  display: flex;
  justify-content: flex-end; /* Align items to the end (right) of the container */
}

.options a {
  margin-right: 20px; /* Adjust the value as needed for the desired space between links */
}



h2 {
  line-height: 1.2em;
}

img {
  max-width: 300px;
  max-height: 250px;
  border-radius: 5px;
}

iframe {
  max-width: 100%;
}

a {
  color: rgb(255, 0, 150);
}

.dp1 {
  background: rgb(255, 0, 150);
  width: fit-content;
  border-radius: 5px;
  line-height: 2em;
}

.dp2 {
  background: rgb(255, 150, 0);
  width: fit-content;
  border-radius: 5px;
  line-height: 2em;
}

.dp3 {
  background: rgb(0, 255, 150);
  width: fit-content;
  border-radius: 5px;
  line-height: 2em;
}

.dp4 {
  background: rgb(150, 255, 0);
  width: fit-content;
  border-radius: 5px;
  line-height: 2em;
}

.dp5 {
  background: rgb(0, 150, 255);
  width: fit-content;
  border-radius: 5px;
  line-height: 2em;
}

.dp6 {
  background: rgb(150, 0, 255);
  width: fit-content;
  border-radius: 5px;
  line-height: 2em;
}

.audio {
  background-color: red;
}

.bmc {
  height: 50px !important;
}

.footer{
  text-align: center;
}
